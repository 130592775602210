import { Tokenizer } from "./s25ql.tokenizer";
import { Item } from "../../pojo/Item";
import { S25Util } from "../../util/s25-util";
import { AdvancedSearchUtil } from "../advanced-search/advanced-search-util";
import { QLTerm, S25QLConst } from "./s25ql.const";
import { SearchCriteria } from "../../pojo/SearchCriteria";
import { SearchService } from "../../services/search/search.service";
import { Proto } from "../../pojo/Proto";
import { QLUtil } from "./s25ql.util";
import QLItemTypes = Tokenizer.QLItemTypes;
import Query = Tokenizer.Query;
import Clause = Tokenizer.Clause;
import ListValue = Tokenizer.ListValue;
import StringValue = Tokenizer.StringValue;
import RangeValue = Tokenizer.RangeValue;
import DateValue = Tokenizer.DateValue;
import MathValue = Tokenizer.MathValue;
import Value = Tokenizer.Value;
import Model = SearchCriteria.Model;
import Searches = SearchCriteria.Searches;
import Step = SearchCriteria.Step;
import Ids = Item.Ids;
import NumericalString = Proto.NumericalString;
import KeywordValue = Tokenizer.KeywordValue;
import StepParam = SearchCriteria.StepParam;
import Option = QLTerm.Option;
import StepTypeId = SearchCriteria.StepTypeId;
import StepType = SearchCriteria.StepType;
import { ProfileUtil } from "../s25-datepattern/profile.util";

function model(type: QLItemTypes, query: Query): { model: Model; searches: Searches } {
    const searches = { nextId: 0 } as Searches & { nextId: number };
    const model = type === Ids.Task ? queryToTaskModel(type, query) : queryToModel(type, query, searches);
    delete searches.nextId;
    postProcessing(type, model, searches);

    return { model, searches };
}

function postProcessing(type: QLItemTypes, model: Model, searches: Searches) {
    model.step = postProcessSteps(type, model.step);
    for (let key in searches) searches[key].step = postProcessSteps(type, searches[key].step);
}

function postProcessSteps(type: QLItemTypes, steps: Step[]) {
    steps = combineCreateInfo(type, steps);
    steps = combineOccInfo(type, steps);
    return steps;
}

function combineCreateInfo(type: QLItemTypes, steps: Step[]) {
    // createDate and createTime are combined into one step
    const hasCreateInfo = !!steps.find((step) => step.step_param?.[0]?.createDateTime);
    if (!hasCreateInfo) return steps;

    const createDateTime: Step = AdvancedSearchUtil.getStep(Ids.Event, SearchCriteria.StepType.Event.CreatedDate);
    for (let step of steps) {
        if (step.step_param[0].createDateTime)
            Object.assign(createDateTime.step_param[0], step.step_param[0].createDateTime);
    }
    return steps.filter((step) => !step.step_param[0].createDateTime).concat(createDateTime);
}

function combineOccInfo(type: QLItemTypes, steps: Step[]) {
    // occDate, occTime, daysOfWeek are combined into one step
    const hasOccDateTimeWeek = !!steps.find((step) => step.step_param?.[0]?.occDateTimeWeek);
    if (!hasOccDateTimeWeek) return steps;

    const occDateTimeWeek: Step = AdvancedSearchUtil.getStep(Ids.Event, SearchCriteria.StepType.Event.Occurrences);
    for (let step of steps) {
        if (step.step_param[0].occDateTimeWeek) {
            Object.assign(occDateTimeWeek.step_param[0], step.step_param[0].occDateTimeWeek);
        }
    }
    return steps.filter((step) => !step.step_param[0].occDateTimeWeek).concat(occDateTimeWeek);
}

function queryToModel(type: QLItemTypes, query: Query, searches: Searches & { nextId: number }): Model {
    const conjunction = query.value.find((item) => item.type === "conjunction")?.value || "and";
    const queryMethod = conjunction === "and" ? "all" : "any";
    const steps = query.value
        .filter((item) => item.type !== "conjunction")
        .map((item: Query | Clause) =>
            item.type === "clause" ? clauseToModel(type, item) : queryToSearch(type, item, searches),
        )
        .filter((step) => step.step_param);

    return {
        ...(steps.length > 1 && { query_method: queryMethod }), // query method is only needed with multiple steps
        step: steps,
    };
}

function queryToSearch(type: QLItemTypes, query: Query, searches: Searches & { nextId: number }) {
    const model = queryToModel(type, query, searches);
    const tempSearchKey = `temp_${searches.nextId}`;
    searches.nextId++;
    searches[tempSearchKey] = model;
    const searchStepType =
        type === Ids.Event ? SearchCriteria.StepType.Event.EventSearch : ((type * 100 + 5) as StepTypeId); // All but event are x05
    const step = AdvancedSearchUtil.getStep(type, searchStepType);
    step.step_param = [{ itemId: tempSearchKey, itemName: tempSearchKey }];
    return step;
}

function queryToTaskModel(type: QLItemTypes, query: Query): Model {
    // Get all clauses. Conjunctions are ignored for tasks.
    const getClauses: (query: Query) => any[] = (query: Query) =>
        query.value
            .filter((item) => item.type !== "conjunction")
            .map((item: Query | Clause) => (item.type === "clause" ? item : getClauses(item)));
    const clauses = S25Util.array.flatten(getClauses(query)) as Clause[];

    const step = AdvancedSearchUtil.getStep(type, 1000);

    for (let clause of clauses) {
        switch (clause.value.term.value) {
            case "type":
            case "state":
                const objKey = `${clause.value.term.value}s`;
                const valueMap: any = {};
                for (let opt of S25QLConst.terms[Ids.Task][clause.value.term.value].options)
                    valueMap[opt.label] = opt.value;
                let values = clause.value.value as ListValue<StringValue>;
                for (let key in step[objKey]) step[objKey][key] = false; // Toggle all off before toggling on
                for (let state of values.value) step[objKey][valueMap[(state as StringValue).value]] = true;
                break;
            case "assignedTo":
            case "assignedFrom":
                const title = clause.value.term.value === "assignedTo" ? "Assigned To" : "Assigned From";
                const contact = step.contactBeans.find((contact: any) => contact.title === title);
                values = clause.value.value as ListValue<StringValue>;
                contact.step_param = values.value.map((val) => parseObjectString(val.value));
                break;
            case "date":
                const range = clause.value.value as RangeValue<DateValue>;
                let start, end;
                if (isDateMathRelative(range.value.start)) start = evalNumberMath(range.value.start as any);
                else start = S25Util.date.toS25ISODateStrStartOfDay(evalDateMath(range.value.start));
                if (isDateMathRelative(range.value.end)) end = evalNumberMath(range.value.end as any);
                else end = S25Util.date.toS25ISODateStrEndOfDay(evalDateMath(range.value.end));
                step.dateBean = [{ step_param: [{ from_dt: start, until_dt: end }] }];
                break;
        }
    }

    return {
        query_method: "all",
        step: [step],
    };
}

function clauseToModel(type: QLItemTypes, clause: Clause) {
    const term = clause.value.term.value;
    const termData = S25QLConst.terms[type][term];

    const step = AdvancedSearchUtil.getStep(type, termData.stepTypeId, termData.subStepTypeId);

    const operator = S25QLConst.operatorModelMap[clause.value.operator.value]?.op || clause.value.operator.value;
    const qualifier = S25QLConst.operatorModelMap[clause.value.operator.value]?.qualifier;
    if (qualifier) step.qualifier = qualifier;
    if (clause.value.value.type === "embedded") {
        if (term === "contact") step.contact_role_id = clause.value.value.value.embeddedValue;
    }

    // Some terms have static values that don't need to be mapped
    if (!termData.static) step.step_param = valueToStepParam(type, term, termData, clause.value.value, operator);
    //if exists and if not exists need their own step_type_id for custom attributes
    if (Number(step.step_type_id) === S25QLConst[type].custAtrb.stepTypeId) {
        // SearchService.custAtrb.setValueOnParam(step.step_param[0], type * 100);
        SearchService.custAtrb.setStepId(step);
    }
    return step;
}

function valueToStepParam(type: QLItemTypes, term: string, termData: QLTerm.Data, value: Value, operator: string) {
    let val: any = value.value;

    // If value comes from an option check if it needs to be exchanged for its value
    if (termData.suggest === "option")
        val = termData.options.find((opt) => opt.label === value.value)?.value || value.value;
    if (termData.suggest === "options") {
        const optionsMap: any = {};
        for (let opt of termData.options) optionsMap[opt.label] = opt.value;
        val = val.map((v: StringValue) => optionsMap[v.value] || v.value);
    }

    // If value is math, evaluate before mapping through search service
    if (value.type === "math") {
        if (termData.type === "number") val = evalNumberMath(value);
        else if (termData.type === "date") {
            if (isDateMathRelative(value)) val = evalNumberMath(value);
            else val = evalDateMath(value);
        }
    }

    // Some model values need to be modified according to the search service
    if (SearchService.replaceValueMap[termData.modelValueParameter]) {
        try {
            const newVal = SearchService.replaceValueMap[termData.modelValueParameter](val);
            if (newVal !== undefined) val = newVal;
        } catch (error: any) {}
    }

    switch (value.type) {
        case "boolean":
            return [{ [termData.modelValueParameter]: val }]; // False is not supported
        case "string":
            if (termData.suggest === "search") return [parseObjectString(val)];

            const ret: any = {};
            if (termData.modelValueParameter) ret[termData.modelValueParameter] = val;
            if (termData.modelValueComparator) ret[termData.modelValueComparator] = operator;
            return [ret];
        case "time":
            if (term === "createTime") return [{ createDateTime: { start_time: val } }];
            return [{}];
        case "list":
            if (termData.suggest === "objects") return valueListToObjectList(val);
            if (term === "daysOfWeek") {
                const daysOfWeek: any = {};
                for (let opt of termData.options) daysOfWeek[opt.value] = val.includes(opt.value) ? "T" : "F";
                return [{ occDateTimeWeek: daysOfWeek }];
            }
            if (term === "includeRelated") {
                return termData.options
                    .filter((opt) => val.includes(opt.value))
                    .map((opt) => ({ itemId: opt.value, itemName: opt.label }));
            }
            if (termData.suggest === "address") {
                const [addressType, address, city, zip, country, phone, fax] = val.map(
                    (item: StringValue) => item.value,
                );
                const address_type_id = ["(none)", "Administration", "Billing"].indexOf(addressType);
                return [{ address_type_id, address, city, zip, country, phone, fax }];
            }
            if (term === "profileCode") {
                // ensure profile code looks like "hh:mm|hh:mm|W1 MO WE" (no ending wildcard until save)
                return val.map((profileCode: StringValue) => {
                    return { profile_code: QLUtil.ProfileCode.addTimeDefaults(profileCode.value) };
                });
            }
            return [{}];
        case "range":
            let start, end;
            if (termData.type === "dateRange") {
                if (isDateMathRelative(val.start)) start = evalNumberMath(val.start);
                else start = S25Util.date.toS25ISODateStrStartOfDay(evalDateMath(val.start));
                if (isDateMathRelative(val.end)) end = evalNumberMath(val.end);
                else end = S25Util.date.toS25ISODateStrEndOfDay(evalDateMath(val.end));
            }
            if (termData.type === "timeRange") {
                start = `${val.start.value}:00`;
                end = `${val.end.value}:00`;
            }
            if (termData.type === "numberRange") {
                start = evalNumberMath(val.start);
                end = evalNumberMath(val.end);
            }

            const range = {
                // from_dt_type: 'date',
                // until_dt_type: 'date',
                [termData.rangeParameters.start]: start,
                [termData.rangeParameters.end]: end,
            };
            // occDate, occTime, and daysOfWeek are combined in post-processing, which is why their structures are weird
            if (term === "occDate") return [{ occDateTimeWeek: range }];
            if (term === "occTime") return [{ occDateTimeWeek: range }];

            return [range];
        case "embedded":
            // For contacts Type and TypeValue are handled in clause
            const embeddedList = valueListToObjectList(val.values);
            // Unlike contacts relationship has the type id right in the step
            if (term === "relationship")
                embeddedList.forEach((item: any) => (item.relationship_type_id = val.embeddedValue));
            return embeddedList;
        case "math":
            if (termData.type === "date") {
                if (term === "createDt") return [{ createDateTime: { from_dt: val } }]; // createDt and createTime are combined in post-processing
                return [{ [termData.modelValueParameter]: val }];
            }
            if (termData.type === "number") return [{ [termData.modelValueParameter]: evalNumberMath(value) }];

            return [{}];
        case "customAttribute":
            const attribute = parseObjectString(val.name);
            let id = val.value;
            let name: string;
            if (!!Number(val.type) && val.operator === "is equal to") {
                const value = parseObjectString(val.value);
                id = value.itemId;
                name = value.itemName;
            }
            const op = val.operator as keyof typeof S25QLConst.customAttributeRelationshipIds;
            if (val.type === "B") id = op === "is True" ? "T" : "F"; // Also covers does/does not exist
            return [
                {
                    cust_atrb_id: attribute.itemId,
                    cust_atrb_name: attribute.itemName,
                    cust_atrb_type: val.type,
                    itemId: id,
                    ...(name && { itemName: name }),
                    relationship_type_id: type * 100 + S25QLConst.customAttributeRelationshipIds[op],
                    relationship_type_name: op,
                },
            ];
    }
}

function valueListToObjectList(values: (Tokenizer.NumberValue | Tokenizer.StringValue)[]) {
    // Objects can be either string or number. Strings need to be parsed into a name and id
    return values.map((val: Tokenizer.NumberValue | Tokenizer.StringValue) => {
        if (val.type === "number") return { itemId: val.value, itemName: "" };
        return parseObjectString(val.value);
    });
}

function parseObjectString(str: string) {
    const { itemId, itemName } = str.match(
        /(?<itemName>.*?)[\s\n\t]*\[ID:[\s\n\t]*(?<itemId>-?\d+)[\s\n\t]*]$/i,
    ).groups;
    return { itemId, itemName };
}

function evalNumberMath(math: MathValue): number {
    let sum = 0;
    let plusOrMinus = 1;
    for (let value of math.value) {
        if (value.type === "operator") plusOrMinus = value.value === "+" ? 1 : -1;
        else if (value.type === "math") sum += plusOrMinus * evalNumberMath(value);
        else if (value.type === "number") sum += plusOrMinus * Number(value.value);
    }
    return sum;
}

function evalDateMath(math: MathValue<DateValue>): Date {
    const addMs = (date: Date, ms: number) => date.setTime(date.getTime() + ms);
    let date = new Date(0); // Start at epoch

    let plusOrMinus = 1;
    for (let value of math.value) {
        if (value.type === "operator") plusOrMinus = value.value === "+" ? 1 : -1;
        else if (value.type === "math") addMs(date, plusOrMinus * evalDateMath(value).getTime());
        else if (value.type === "date") addMs(date, plusOrMinus * Date.parse(value.value));
        else if (value.type === "number") date = S25Util.date.addDays(date, plusOrMinus * value.value);
    }

    return date;
}

function isDateMathRelative(math: MathValue<DateValue | KeywordValue>): boolean {
    return !!math.value.find((item) => {
        if (item.type === "math") return isDateMathRelative(item);
        return item.type === "keyword";
    });
}

function serialize(type: Item.Id, model: Model, searches: Searches): string {
    const serial = serializeModel(type, model, searches).replace(/^\((.*)\)$/, "$1");
    return `::${serial}`;
}

function serializeModel(type: Item.Id, model: Model, searches: Searches): string {
    const conjunction = model.query_method === "any" ? "or" : "and";

    const values = model.step
        .map((step) => {
            if (type === Item.Ids.Task) return serializeTaskStep(step);
            if (!step.step_param) return "";
            if (QLUtil.isStepTemp(step)) {
                // This is a temp search, get it from the searches
                return serializeModel(type, searches[step.step_param?.[0]?.itemName], searches);
            }
            return serializeStep(type, step);
        })
        .filter((val) => val);

    return `(${values.join(` ${conjunction} `)})`;
}

function serializeTaskStep(step: any): string {
    const parts: string[] = [];

    const states = Object.entries(step.states)
        .filter(([name, yes]) => yes)
        .map(([name, yes]) => {
            const label = S25QLConst.terms[Item.Ids.Task].state.options.find((opt: Option) => opt.value === name).label;
            return `"${label}"`;
        });
    if (states.length) parts.push(`state in (${states.join(", ")})`);

    const types = Object.entries(step.types)
        .filter(([name, yes]) => yes)
        .map(([name, yes]) => {
            const label = S25QLConst.terms[Item.Ids.Task].type.options.find((opt: Option) => opt.value === name).label;
            return `"${label}"`;
        });
    if (types.length) parts.push(`type in (${types.join(", ")})`);

    let { from_dt, from_dt_type, from_dt_num, from_dt_date } = step.dateBean[0].step_param[0];
    let { until_dt, until_dt_type, until_dt_num, until_dt_date } = step.dateBean[0].step_param[0];

    from_dt = !from_dt_type
        ? SearchService.fromHelper(from_dt).from_dt
        : from_dt_type === "number"
          ? from_dt_num
          : from_dt_date;
    until_dt = !from_dt_type
        ? SearchService.untilHelper(until_dt).until_dt
        : until_dt_type === "number"
          ? until_dt_num
          : until_dt_date;
    if (Number(from_dt) !== 0 || Number(until_dt) !== 1) {
        const from = getAbsoluteOrRelativeDate(from_dt, "today");
        const until = getAbsoluteOrRelativeDate(until_dt, "start");
        parts.push(`date between ${from} and ${until}`);
    }

    const assignedTo = step.contactBeans?.find((bean: any) => bean.title === "Assigned To")?.step_param || [];
    if (assignedTo.length) {
        const contacts = assignedTo
            .filter((item: any) => item.itemName)
            .map(({ itemId, itemName }: any) => `"${itemName} [ID:${itemId}]"`);
        if (!!contacts.length) parts.push(`assignedTo in (${contacts.join(", ")})`);
    }

    const assignedFrom = step.contactBeans?.find((bean: any) => bean.title === "Assigned From")?.step_param || [];
    if (assignedFrom.length) {
        const contacts = assignedFrom
            .filter((item: any) => item.itemName)
            .map(({ itemId, itemName }: any) => `"${itemName} [ID:${itemId}]"`);
        if (!!contacts.length) parts.push(`assignedFrom in (${contacts.join(", ")})`);
    }

    return parts.join(" and ");
}

function serializeStep(type: Item.Id, step: Step): string {
    // Find term
    let stepTypeId =
        S25QLConst.stepNormMap[Number(step.step_type_id) as keyof typeof S25QLConst.stepNormMap] ||
        Number(step.step_type_id);
    if (/Custom Attributes(\.\.\.)?/i.test(step.step_type_name)) stepTypeId = type * 100 + 50; // Custom attributes have step type ids based on their operator for some reason
    const terms = S25QLConst.terms[type];
    let composed: string[] = [];
    for (let term in terms) {
        if (!terms.hasOwnProperty(term) || terms[term].stepTypeId !== stepTypeId) continue;

        const termData = terms[term];
        if (!termData) continue;

        // Skip if
        if (termData?.skipIf?.(step)) continue;

        // Serialize based on type
        const param = step.step_param[0];
        if (!param) continue; // Skip if no data

        let operator: string = termData.operations[0];
        let value: string;

        switch (termData.type) {
            case "string":
                if (!param[termData.modelValueParameter]) continue; // No value => wrong term
                value = serializeString(param, termData);

                if (termData.modelValueComparator) {
                    const opVal = param[termData.modelValueComparator];
                    operator =
                        Object.entries<{ op: string; qualifier?: NumericalString }>(S25QLConst.operatorModelMap).find(
                            ([key, { op }]) => op === opVal,
                        )?.[0] || opVal;
                    operator = termData.operations.includes(operator) ? operator : termData.operations[0] || opVal;
                }
                break;
            case "boolean":
                value = serializeBoolean(param, termData);
                break;
            case "date":
                const dateValue = param[termData.modelValueParameter];
                if (isNaN(dateValue) && !dateValue) continue; // No value => wrong term
                if (dateValue === null) continue;
                value = serializeDate(param, termData);
                break;
            case "stringList":
            case "stringOrNumberList":
                if (term === "daysOfWeek") {
                    value = serializeDaysOfWeek(param, termData);
                    if (!value) continue;
                } else if (term === "address") value = serializeAddress(param, termData);
                else if (termData.options) value = serializeOptions(step, param, termData);
                else {
                    operator = Object.values<{ op: string; qualifier?: NumericalString }>(
                        S25QLConst.operatorModelMap,
                    ).find((op) => op.qualifier == step.qualifier).op;
                    value = serializeList(step, param, termData);
                }
                break;
            case "embedded":
                operator = Object.values<{ op: string; qualifier?: NumericalString }>(S25QLConst.operatorModelMap).find(
                    (op) => op.qualifier == step.qualifier,
                ).op;
                value = serializeEmbedded(step, param, termData);
                break;
            case "dateRange":
                value = serializeDateRange(param, termData);
                break;
            case "timeRange":
                value = serializeTimeRange(param, termData);
                break;
            case "numberRange":
                value = serializeNumberRange(param, termData);
                break;
            case "time":
                value = serializeTime(param, termData);
                break;
            case "customAttribute":
                value = serializeCustomAttribute(param, termData);
                break;
            case "number":
                if (!param[termData.modelValueParameter]) continue; // No value => wrong term
                value = serializeNumber(param, termData);
                break;
            case "profileCode":
                // if profile_code ends in '%' then val should remove that char AND operator becomes 'startsWith'
                if (!param[termData.modelValueParameter]) continue; // No value => wrong term
                operator = Object.values<{ op: string; qualifier?: NumericalString }>(S25QLConst.operatorModelMap).find(
                    (op) => op.qualifier == step.qualifier,
                ).op;
                value = serializeProfileCode(step, param, termData);
                break;
        }

        if (termData.isComposed) {
            // Some terms share a step and need to be combined here
            if (value !== undefined) composed.push(`${term} ${operator} ${value}`);
            continue;
        }
        if (value === undefined) return;
        return `${term} ${operator} ${value}`;
    }
    return composed.join(" and ");
}

/**
 * profile codes are stored as "hh:mm|hh:mm|W1 MO WE" in the database (startTm|endTm|profileCode)
 * exact match on hh:mm means no times specified
 * In s25QL we want to show only relevant parts,
 *    - never show ending wildcard
 *    - only show start/end time when at least one is specified (not hh:mm)
 * @param step
 * @param param
 * @param termData
 */
function serializeProfileCode(step: Step, param: StepParam, termData: QLTerm.Data): string {
    const profileCodes = step.step_param.map((param) => {
        let profileCode = param[termData.modelValueParameter];
        if (ProfileUtil.getProfileCodeEndingType(profileCode) === "none") {
            profileCode = QLUtil.ProfileCode.getProfileCodeWithoutWildcard(profileCode);
        }
        return `"${QLUtil.ProfileCode.removeTimeDefaults(profileCode)}"`;
    });

    return `(${profileCodes.join(", ")})`;
}

function serializeString(param: StepParam, termData: QLTerm.Data): string {
    // Get value
    let str = String(param[termData.modelValueParameter]);
    if (termData.options) {
        str = termData.options.find((opt) => opt.value === str)?.label || str;
    }
    // Include item ID if necessary
    let includeId =
        (termData.suggest === "object" && termData.objectType !== "contacts") || termData.suggest === "search";
    if (includeId) return toObjectString(param);
    const quote = str.includes('"') ? "'" : '"'; // Strings are wrapped in quotes
    return `${quote}${str}${quote}`;
}

function serializeBoolean(param: StepParam, termData: QLTerm.Data): string {
    if (!termData.modelValueParameter) return "true";
    return S25Util.toBool(param[termData.modelValueParameter]) ? "true" : "false";
}

function serializeDate(param: StepParam, termData: QLTerm.Data): string {
    // Term createDt allows "today" keyword
    const dateValue = getAbsoluteOrRelativeDate(param[termData.modelValueParameter], "today");
    return String(dateValue);
}

function serializeOptions(step: Step, param: StepParam, termData: QLTerm.Data): string {
    const listValues = step.step_param.map(
        (item) => `"${termData.options.find((opt) => opt.value == item.itemId).label}"`,
    );
    return `(${listValues.join(", ")})`;
}

function serializeDaysOfWeek(param: StepParam, termData: QLTerm.Data): string {
    const listValues = termData.options
        .filter((opt) => param[opt.value] === "T" || param[opt.value] === true)
        .map((opt) => `"${opt.label}"`);
    if (listValues.length === 7 || !listValues.length) return;
    return `(${listValues.join(", ")})`;
}

function serializeAddress(param: StepParam, termData: QLTerm.Data): string {
    const { address_type_name, address, city, zip, country, phone, fax } = param;
    return `("${address_type_name}", "${address}", "${city}", "${zip}", "${country}", "${phone}", "${fax}")`;
}

function serializeList(step: Step, param: StepParam, termData: QLTerm.Data): string {
    const includeId = termData.suggest === "objects";
    const stringValues = step.step_param.map((param) => {
        if (includeId) return toObjectString(param);
        const quote = String(param.itemName).includes('"') ? "'" : '"'; // Strings are wrapped in quotes
        return `${quote}${param.itemName}${quote}`;
    });
    if (!stringValues.length) return;
    return `(${stringValues.join(", ")})`;
}

function serializeNumber(param: StepParam, termData: QLTerm.Data): string {
    return param[termData.modelValueParameter];
}

function serializeEmbedded(step: Step, param: StepParam, termData: QLTerm.Data): string {
    const embeddedType = termData.suggest === "contactRole" ? "role" : "relationship";
    const type = termData.suggest === "contactRole" ? step["contact_role_id"] : param["relationship_type_id"];
    const embeddedValues = step.step_param.map(toObjectString);
    return `("${embeddedType}:${type || 0}", ${embeddedValues.join(", ")})`;
}

function serializeDateRange(param: StepParam, termData: QLTerm.Data): string {
    let startDate = getAbsoluteOrRelativeDate(param[termData.rangeParameters.start], "today");
    let endDate = getAbsoluteOrRelativeDate(param[termData.rangeParameters.end], "start");
    if (startDate === "today" && endDate === "start") return;
    return `${startDate} and ${endDate}`;
}

function serializeTimeRange(param: StepParam, termData: QLTerm.Data): string {
    const start = param[termData.rangeParameters.start];
    const end = param[termData.rangeParameters.end];
    if (!start || !end) return;
    const startTime = getTime(start);
    const endTime = getTime(end);
    if (startTime === "00:00" && endTime === "23:59") return;
    return `${startTime} and ${endTime}`;
}

function serializeNumberRange(param: StepParam, termData: QLTerm.Data): string {
    const startNumber = param[termData.rangeParameters.start];
    const endNumber = param[termData.rangeParameters.end];
    if (startNumber === undefined || endNumber === undefined) return;
    return `${startNumber} and ${endNumber}`;
}

function serializeTime(param: StepParam, termData: QLTerm.Data): string {
    const time = getTime(param[termData.modelValueParameter]);
    if (time === "00:00" || time === "23:59") return undefined; // Ignore start and end times
    return time;
}

function serializeCustomAttribute(param: StepParam, termData: QLTerm.Data): string {
    const attributeName = param.cust_atrb_name;
    const attributeId = param.cust_atrb_id;
    let attributeOperator =
        S25QLConst.customAttributeRelationshipMap[param.relationship_type_name] || param.relationship_type_name;
    const attributeType = param.cust_atrb_type;
    if (attributeType === "B" && attributeOperator === "is equal to")
        attributeOperator = param.itemId === "T" ? "is True" : "is False";
    let attributeValue = param.itemId ?? "";
    if (attributeOperator === "contains") attributeValue = attributeValue.replace(/^%(.*?)%$/, "$1");
    else if (attributeOperator === "starts with") attributeValue = attributeValue.replace(/^(.*?)%$/, "$1");
    if (param.itemName) attributeValue = `${param.itemName} [ID:${param.itemId}]`;
    if (
        S25QLConst.noValueCustomAttributeTypes.has(attributeType) ||
        S25QLConst.noValueCustomAttributeOperators.has(attributeOperator)
    ) {
        return `("${attributeName} [ID:${attributeId}]", "${attributeOperator}", "${attributeType}")`;
    }
    if (attributeValue === null || attributeValue === undefined) return; // Should have value at this point
    return `("${attributeName} [ID:${attributeId}]", "${attributeOperator}", "${attributeType}", "${attributeValue}")`;
}

function toObjectString(object: { itemName: string; itemId: number | NumericalString }) {
    const quote = String(object.itemName).includes('"') ? "'" : '"'; // Strings are wrapped in quotes
    return `${quote}${object.itemName} [ID:${object.itemId}]${quote}`;
}

function getAbsoluteOrRelativeDate(date: string | number | Date, keyword: string): string | number {
    if (!(date instanceof Date) && !isNaN(Number(date as string))) {
        const sign = Math.sign(date as number) > 0 ? "+" : "-";
        const val = Math.abs(date as number);
        if (!val) return keyword;
        else return `${keyword} ${sign} ${val}`;
    }
    return getDateTime(String(date));
}

function getDateTime(date: Date | string) {
    return S25Util.date.toS25ISODateTimeStr(date).slice(0, -3); // slice(0, -3) to remove seconds
}

function getTime(date: Date | string) {
    return S25Util.date.toS25ISOTimeStr(date).replace(/^(\d\d:\d\d)(:\d\d)?$/, "$1");
}

function validate(itemTypeId: Item.Id, model: Model, searches: Searches): string {
    const steps: SearchCriteria.Step[] = S25Util.array
        .flatten(Object.values(searches).map((search) => search.step))
        .concat(model.step);

    if (itemTypeId === Item.Ids.Event) {
        // hasRelated and hasBound can only be used with other clauses
        const isRelOrBound = (step: SearchCriteria.Step) => step.step_type_id === StepType.Event.IncludeRelatedEvents;
        const relOrBoundSteps = steps.filter(isRelOrBound).length;
        if (relOrBoundSteps >= 1 && relOrBoundSteps === steps.length)
            return 'Criteria "Has Related Events" and "Has Bound Events" may only be used in conjunction with other criteria.';
    }
    return;
}

export const S25QLModeller = {
    model,
    serialize,
    validate,
};
